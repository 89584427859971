<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <TransitionRoot
      as="template"
      :show="open"
    >
      <Dialog
        as="div"
        static
        class="fixed inset-0 overflow-hidden"
        @close="open = false"
        :open="open"
      >
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay class="absolute inset-0" />
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div class="w-screen max-w-md">
                <div class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                ">
                  <div class="py-6 px-4 bg-light-blue-600 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Send Quote Email
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          class="
                          bg-light-blue-900
                          rounded-md
                          text-blue-200
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon
                            class="h-6 w-6"
                            aria-hidden="true"
                          />
                          </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">
                        Fill in the information below to send a new quote, which will be emailed to the client to start the application.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex-1 py-6 px-4 sm:px-6">
                    <div class="space-y-6 sm:space-y-5">
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-gray-200
                      ">
                        <label
                          for="client_name"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Client Name
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="text"
                              name="client_name"
                              id="client_name"
                              autocomplete="given-name"
                              class="
                            max-w-lg
                            block
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:max-w-xs sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="client_email"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Client Email
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="email"
                              name="client_email"
                              id="client_email"
                              autocomplete="email"
                              class="
                            max-w-lg
                            block
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:max-w-xs sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="subject"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Subject
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="subject"
                              name="subject"
                              type="text"
                              class="
                            block
                            max-w-lg
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="text"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Text
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea
                              id="text"
                              name="text"
                              rows="3"
                              class="
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            ></textarea>
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="type_of_sale"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Transaction
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                              id="type_of_sale"
                              name="type_of_sale"
                              class="
                                form-select appearance-none
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                              "
                              v-model="type_of_sale"
                              @change="getPreview"
                            >
                              <option value='' selected>Select Type</option>
                              <option
                                v-for="type in types"
                                :key="type.uid"
                                :value="type.uid"
                              >{{type.name}}</option>
                                </select>
                          </div>
                      </div>
                      <div v-if="type_of_sale != 'sale'" class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="type_of_sale"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Stamp duty
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                              id="buyer"
                              name="buyer"
                              class="
                                form-select appearance-none
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                              "
                            >
                              <option selected>Select Type</option>
                              <option value="first_time">First Time Buyer</option>
                              <option value="normal">Standard</option>
                              <option value="additional">Higher rate</option>

                              </select>
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="property_price"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Property Price
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="property_price"
                              name="property_price"
                              type="text"
                              class="
                            block
                            max-w-lg
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                              @change="getPreview"
                              @input="handleOnChange"
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="type_of_sale"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                        Legel Fee Range
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                              id="legel_fee_range"
                              name="legel_fee_range"
                              class="
                                form-select appearance-none
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                              "
                              @change="getSelectLegelFees"
                            >
                              <option value='' selected>Select Range</option>
                              <option
                                v-for="type in allLegelFees"
                                :key="type.id"
                                :value="type.id"
                              >{{ type.price_from }} - {{ type.price_to }}  ({{ type.name }})</option>
                                </select>
                          </div>
                      </div>
                      
                      <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Legel Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="legel_fee"
                                    name="legel_fee"
                                    v-model.number="legel_fee" 
                                    type="text"
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                      <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Land Registry Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="land_registry_fee"
                                    name="land_registry_fee"
                                    v-model.number="land_registry_fee" 
                                    type="text"
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div v-if="type_of_sale != 'sale'"  class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Land Registry Searches </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="land_registry_search"
                                    name="land_registry_search"
                                    v-model.number="land_registry_search" 
                                    type="text"
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Search Packs</label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="search_packs"
                                    name="search_packs"
                                    v-model.number="search_packs" 
                                    type="text"
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > VAT </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="vat"
                                    name="vat"
                                    type="text"
                                    v-model.number="vat_fee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Mortgage Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="mortgage_fee"
                                    name="mortgage_fee"
                                    type="text"
                                    v-model.number="mortgage_fee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > ID verification Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="id_verfication_fee"
                                    name="id_verfication_fee"
                                    style="display:none;"
                                    type="text"
                                    v-model.number="id_verfication_fee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Electronic Transfer Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="electronic_transfer_fee"
                                    name="electronic_transfer_fee"
                                    type="text"
                                    v-model.number="electronic_transfer_fee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Archive Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="archive_fee"
                                    name="archive_fee"
                                    style="display:none;"
                                    type="text"
                                    v-model.number="archive_fee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Verification of Lawyer Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="lawyer_fee"
                                    name="lawyer_fee"
                                    type="text"
                                    style="display:none;"
                                    v-model.number="lawyer_fee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Stamp Duty Land Tax Fee </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="SDLT_fee"
                                    name="SDLT_fee"
                                    type="text"
                                    v-model.number="SDLTfee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                          <label
                              for="range_to"
                              class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Stamp Duty </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                    id="stamp_duty_fee"
                                    name="stamp_duty_fee"
                                    type="text"
                                    v-model.number="stamp_duty_fee" 
                                    class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                    @change="AddTotalFees"
                                    required
                                /> </div>
                        </div>
                        <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                        <label
                            for="storage_fee"
                            class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                        > Storage Fee </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                  id="storage_fee"
                                  name="storage_fee"
                                  v-model.number="storage_fee"
                                  type="text"
                                  class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                  required
                              /> </div>
                      </div>
                      <div v-if="type_of_sale != 'purchase'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                        <label
                            for="range_to"
                            class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                        > Mortgage Discharge Fee </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                  id="mortgage_discharge_fee"
                                  name="mortgage_discharge_fee"
                                  v-model.number="mortgage_discharge_fee"
                                  type="text"
                                  class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                  required
                              /> </div>
                      </div>
                      <div v-if="type_of_sale != 'purchase'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                        <label
                            for="range_to"
                            class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                        > Draft Contract Fee </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                  id="draft_contract_fee"
                                  name="draft_contract_fee"
                                  v-model.number="draft_contract_fee"
                                  type="text"
                                  class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                  required
                              /> </div>
                      </div>
                      <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                          <label
                            for="range_to"
                            class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                          > Confirmation of Transfer Fee </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                  id="transfer_fee"
                                  name="transfer_fee"
                                  v-model.number="transfer_fee"
                                  type="text"
                                  class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                  required
                                /> </div>
                      </div>
                      <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 " style="display:none;">
                        <label
                            for="range_to"
                            class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                        > Confirmation of Ownership Fee </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                  id="ownership_fee"
                                  name="ownership_fee"
                                  v-model.number="ownership_fee"
                                  type="text"
                                  class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                  required
                              /> </div>
                      </div>
                      <div v-if="type_of_sale != 'sale'" class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                        <label
                            for="range_to"
                            class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                        > Bankruptcy Fee </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                  id="bankruptcy_fee"
                                  name="bankruptcy_fee"
                                  v-model.number="bankruptcy_fee"
                                  type="text"
                                  class=" block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md "
                                  required
                              /> </div>
                      </div>
                        <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="preview_price"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Preview Price
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="preview_price"
                              name="preview_price"
                              type="text"
                              v-model.number="preview_fee" 
                              class="
                            block
                            max-w-lg
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                              readonly
                           
                            />
                          </div>
                      </div>
                     
                    </div>
                    <div class="sm:grid sm:grid-cols-1 text-red-700 help-desk-error  px-1 py-1"></div>
                    <div class=" sm:grid sm:grid-cols-1 text-green-700 help-desk-success  px-1 py-1"></div>
                  </div>
                  <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      @click="open = false"
                    >
                      Cancel
                      </button>
                      <button
                        type="submit"
                        class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        @click="sendEmail"
                      >
                        Send
                        </button>
                  </div>
                </div>
              </div>
              </TransitionChild>
          </div>
        </div>
        </Dialog>
        </TransitionRoot>
        <div class="flex flex-col">
          <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <nav
              class="
          flex
          mb-8
          justify-between
          sm:px-6
          lg:px-8
          flex-wrap
          gap-y-4
          lg:h-10
          md:h-10
        "
              aria-label="Breadcrumb"
            >
              <ol class="flex items-center space-x-4">
                <li>
                  <div>
                    <a
                      href="#"
                      class="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        class="flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span class="sr-only">Home</span>
                      </a>
                  </div>
                </li>
                <li
                  v-for="page in pages"
                  :key="page.name"
                >
                  <div class="flex items-center">
                    <ChevronRightIcon
                      class="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <a
                      :href="page.href"
                      class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      :aria-current="page.current ? 'page' : undefined"
                    >{{ page.name }}</a>
                  </div>
                  </li>
              </ol>
              <div class="flex flex-wrap gap-4 items-center">
                <div class="relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <SearchIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="email"
                    class="
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                pl-10
                sm:text-sm
                border-gray-300
                rounded-md
              "
                    placeholder="Search for cases"
                  />
                </div>
                <router-link
                  :to="{ name: 'sent-quotes' }"
                  class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-transparent
                    shadow-sm
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-yellow-600
                    hover:bg-yellow-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-light-blue-500"
                >
                  <MailIcon
                    class="mr-3 h-5 w-5"
                    aria-hidden="true"
                  /> View sent quotes
                </router-link>
                    <button
                    @click="open = true"
                    type="button"
                    class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-transparent
                    shadow-sm
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-light-blue-600
                    hover:bg-light-blue-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-light-blue-500
                  "
                  >
                <FolderAddIcon
                  class="mr-3 h-5 w-5"
                  aria-hidden="true"
                /> Send quote email
                </button>
              </div>
              </nav>
              <div class="
          py-2
          align-middle
          inline-block
          min-w-full
          sm:px-6
          lg:px-8
          max-w-full
        ">
                <div v-if="cases.items.length > 0"
                  class="
            shadow
            border-b border-gray-200
            sm:rounded-tl-lg sm:rounded-tr-lg
            overflow-auto
          "
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                        >
                          NAME & START DATE
                          </th>
                          <th
                            scope="col"
                            class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                          >
                            CLIENT
                            </th>
                            <th
                              scope="col"
                              class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                            >
                              STAFF
                              </th>
                              <th
                                scope="col"
                                class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                              >
                                STAGE
                                </th>
                                <th
                                  scope="col"
                                  class="relative px-6 py-3"
                                >
                                  <span class="sr-only">Edit</span>
                                  </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr
                        :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                        v-for="(item, i) in cases.items"
                        :key="item.id"
                      >
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div>
                              <div
                                :title="item.info.address.street_address"
                                class="
                          text-sm
                          font-medium
                          text-gray-900
                          lg:truncate
                          md:truncate
                          max-w-xs
                        "
                              >
                                {{ item.info.address.street_address }}
                            </div>
                            <div class="text-sm text-gray-500">
                              {{ DateTime.fromISO(item.created_at).toFormat( "MMMM dd, yyyy" ) }}
                            </div>
                          </div>
                </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <router-link
                    :to="{ name: 'client', params: { id: item.user.id } }"
                    class="flex items-center"
                  >
                    <div class="flex-shrink-0 h-10 w-10">
                      <Avatar
                        :user="item.user"
                        :className="['h-8', 'w-8', 'rounded-full']"
                      />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ item.user.first_name }} {{ item.user.last_name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ item.user.email }}
                      </div>
                    </div>
                    </router-link>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center w-40">
                    <div class="flex -space-x-2 overflow-hidden">
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                        alt=""
                      />
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    :class="`text-${item.info.stage.color}-500 bg-${item.info.stage.color}-100`"
                    class="
                      inline-flex
                      items-center
                      px-2.5
                      py-0.5
                      rounded-md
                      text-sm
                      font-medium
                    "
                  >
                    <svg
                      :class="`text-${item.info.stage.color}-200`"
                      class="-ml-0.5 mr-1.5 h-2 w-2"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle
                        cx="4"
                        cy="4"
                        r="3"
                      />
                      </svg>
                      {{ item.info.stage.name }}
                      </span>
                </td>
                <td class="
                    px-6
                    py-4
                    whitespace-nowrap
                    text-right text-sm
                    font-medium
                  ">
                  <router-link
                    :to="{ name: 'case', params: { id: item.id } }"
                    class="text-blue-600 hover:text-blue-900"
                  >View-></router-link>
                </td>
                </tr>
                </tbody>
                </table>
              </div>
              <Pagination
                @page-changed="handlePageChanged"
                :total="cases.count"
                :current_page="current_page"
              />
          </div>
        </div>
  </div>
  </div>
</template>

<script>
import {
  ChevronRightIcon,
  HomeIcon,
  SearchIcon,
  FolderAddIcon,
  MailIcon
} from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import axios from "axios";
const pages = [{ name: "Cases", href: "#", current: true }];

import { ref, computed, onMounted } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import Pagination from "@/components/Pagination";
import Avatar from "@/components/Avatar";
import { getAppTypes } from "../../../services/case";

export default {
  components: {
    Avatar,
    ChevronRightIcon,
    HomeIcon,
    SearchIcon,
    FolderAddIcon,
    MailIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Pagination
  },
  setup() {
    const store = useStore();
    const types = ref("");
    let SDLTfee = ref(0);
    const allLegelFees = ref("");

    onMounted(async () => {
      getAllLegelFees();
      try {
        const { data } = await getAppTypes();
        types.value = data;
      } catch (e) {
        console.log(e);
      }
      types.value.sort((a, b) => { 
        if (a.uid < b.uid) {
          return 1;
        }
        if (a.uid > b.uid) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    });

    store.dispatch("cases/getCases");
    const getAllLegelFees  = async () =>{
      await store.dispatch("cases/getAllLegelFees");
      const respData = store.state.cases.legalFee.items;
      // console.log(respData);
      allLegelFees.value = respData.filter((value) => {
          return value.admin == true;
      });
      // console.log(allLegelFees.value);
    }

    const open = ref(false);
    const current_page = ref(1);
    const handlePageChanged = async page => {
      await store.dispatch("cases/getCases", page);
      current_page.value = page;
    };
    const handleOnChange = e => {
      let value = e.target.value.replaceAll(",", "");
      e.target.value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    
    return {
      handleOnChange,
      open,
      pages,
      DateTime,
      handlePageChanged,
      current_page,
      cases: computed(() => store.state.cases.cases),
      // sendEmail,
      types,
      allLegelFees,
      SDLTfee,
      store
    };
  },
  data(){
     return {
        type_of_sale: '', 
        legel_fee: '',
        land_registry_fee: '',	
        land_registry_search: '',
        search_packs: '',
        mortgage_fee:  '',
        vat_fee:   '',
        id_verfication_fee: '',
        electronic_transfer_fee:  '',
        archive_fee:  '',
        lawyer_fee:  '',
        stamp_duty_fee: '',
        storage_fee: '', 
        mortgage_discharge_fee: '', 
        draft_contract_fee: '', 
        ownership_fee: '', 
        bankruptcy_fee: '', 
        transfer_fee: '', 
        preview_fee: 0
     }
  },
  methods:{
      async getPreview(){
        let Tax125 = 0,
          Tax250 = 0,
          Tax925 = 0,
          Tax = 0,
          Tax0 = 0;
       
        let property = document.getElementById("type_of_sale").value;

        await this.store.dispatch("cases/getLegelFees", property);

        // console.log("store.state.cases.legalData", store.state.cases.legalFee.items);
        let respData = this.store.state.cases.legalFee.items;
        // console.log(respData);

        respData = respData.filter((value) => {
                        return value.admin == true;
                    });

        this.allLegelFees = respData.sort((a,b) => {
            return a.price_from >= b.price_from && a.admin == true && b.admin == true;
        });

        let property_price = document
          .getElementById("property_price")
          .value.replaceAll(",", "");
        if(document.getElementById("buyer")){
          let buyer = document.getElementById("buyer").value;
          console.log("property", property);
          if (property_price == "") {
            return;
          }

          if (property == "purchase" || property == "both") {
            console.log("here", property_price);
            let value = property_price.replaceAll(",", "");
            if (value > 0) {
              if (buyer === "additional") {
                let valAdd = value >= 125000 ? 125000 : value;
                let TaxableVal0 = valAdd;
                Tax0 = TaxableVal0 * 0.03;
              }
            }
            // if (value >= 125000) {
            //   let val = value >= 250000 ? 250000 : value;
            //   let TaxableVal125 = val - 125000;
            //   Tax125 = TaxableVal125 * 0.02;
            // }
            if(buyer === "normal"){
              if (value >= 250000) {
                let val = value >= 925000 ? 925000 : value;
                let TaxableVal250 = val - 250000;
                Tax250 = TaxableVal250 * 0.05;
              }
              if (value >= 925000) {
                let val = value >= 1500000 ? 1500000 : value;
                let TaxableVal925 = val - 925000;
                Tax925 = TaxableVal925 * 0.1;
              }
              if (value >= 1500000) {
                let val = value;
                let TaxableVal = val - 1500000;
                Tax = TaxableVal * 0.12;
              }
            }

            if( buyer === "additional"){
                    
              if (value >= 250000) {
                let val = value >= 925000 ? 925000 : value;
                let TaxableVal250 = val - 250000;
                Tax250 = TaxableVal250 * 0.08;
              }
              if (value >= 925000) {
                let val = value >= 1500000 ? 1500000 : value;
                let TaxableVal925 = val - 925000;
                Tax925 = TaxableVal925 * 0.13;
              }
              if (value >= 1500000) {
                let val = value;
                let TaxableVal = val - 1500000;
                Tax = TaxableVal * 0.15;
              }

            }
            let total = Tax0 + Tax125 + Tax250 + Tax925 + Tax;
            total = total.toFixed(2);
            this.SDLTfee = total;
            // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            console.log("SDLTfee", this.SDLTfee);
            document.getElementById("SDLT_fee").value = this.SDLTfee;
            
          }
        }

        this.AddTotalFees();

      },
      AddTotalFees(){
        
        this.stamp_duty_fee = 0;
        let Total = 0;
        if(this.type_of_sale === 'sale'){

          Total =
            Number(this.legel_fee) +
            Number(this.mortgage_discharge_fee) +
            Number(this.draft_contract_fee) +
            Number(this.storage_fee) +
            // Number(this.transfer_fee) +
            Number(this.vat_fee) +
            Number(this.electronic_transfer_fee);

        }else if(this.type_of_sale === 'purchase'){
             
          Total =
            Number(this.legel_fee) +
            Number(this.land_registry_fee) +
            Number(this.land_registry_search) +
            Number(this.storage_fee) +
            Number(this.search_packs) +
            Number(this.electronic_transfer_fee) +
            // Number(this.ownership_fee) +
            Number(this.mortgage_fee) +
            Number(this.SDLTfee) +
            Number(this.stamp_duty_fee)+
            Number(this.vat_fee) +
            Number(this.bankruptcy_fee);

          
        }else if(this.type_of_sale === 'both'){
             
          Total =
            Number(this.legel_fee) +
            Number(this.mortgage_discharge_fee) +
            Number(this.draft_contract_fee) +
            Number(this.storage_fee) +
            // Number(this.transfer_fee) +
            Number(this.electronic_transfer_fee) +
            Number(this.legel_fee) +
            Number(this.land_registry_fee) +
            Number(this.land_registry_search) +
            Number(this.storage_fee) +
            Number(this.search_packs) +
            Number(this.electronic_transfer_fee) +
            // Number(this.ownership_fee) +
            Number(this.mortgage_fee) +
            Number(this.SDLTfee) +
            Number(this.stamp_duty_fee)+
            Number(this.vat_fee) +
            Number(this.bankruptcy_fee);
          
        }
      

        this.preview_fee = Total;

        console.log('Total--',Total);
      },
      getSelectLegelFees(){
      
        // console.log('testts',this.allLegelFees);
        let LegelFeeRange = document.getElementById("legel_fee_range").value;
        
        const filterData = this.allLegelFees.filter(function(value){
            return value.id == LegelFeeRange && value.admin == true;
        });

        if (filterData.length > 0) {
          this.legel_fee = filterData[0].fees;
          this.land_registry_fee = filterData[0].land_registry_fee;
          this.land_registry_search = filterData[0].land_registry_search;
          this.search_packs = filterData[0].search_packs;
          this.vat_fee = filterData[0].vat_fee;
          this.mortgage_fee = filterData[0].mortgage_fee;
          this.id_verfication_fee = filterData[0].id_verfication_fee;
          this.electronic_transfer_fee = filterData[0].electronic_transfer_fee;
          this.archive_fee = filterData[0].archive_fee;
          this.lawyer_fee = filterData[0].lawyer_fee;
          this.storage_fee = filterData[0].storage_fee;
          this.mortgage_discharge_fee = filterData[0].mortgage_discharge_fee;
          this.draft_contract_fee = filterData[0].draft_contract_fee;
          this.ownership_fee = filterData[0].ownership_fee;
          this.bankruptcy_fee = filterData[0].bankruptcy_fee;
          this.transfer_fee = filterData[0].transfer_fee;

        }
        
        this.AddTotalFees();
 
    },
    //send email
    async sendEmail(){
      let client_name = document.getElementById("client_name").value;
      let client_email = document.getElementById("client_email").value;
      let subject = document.getElementById("subject").value;
      let type_of_sale = document.getElementById("type_of_sale").value;
      let property_price = document.getElementById("property_price").value;
      // let selectAll = document.getElementById("select-all").checked;
      // let messageType = document.getElementById("message-type").value;
      let text = document.getElementById("text").value;
      let messageType ='dfd';
      document.getElementsByClassName("help-desk-error")[0].innerHTML = "";
      document.getElementsByClassName("help-desk-success")[0].innerHTML = "";
      let selectAll = 'test';
      let discountFee;
      if (selectAll) {
        discountFee = "Fee type 1,Fee type 2";
      } else {
        discountFee = messageType;
      }
      if (
        client_name == "" ||
        client_email == "" ||
        subject == "" ||
        type_of_sale == "" ||
        property_price == "" ||
        text == ""
      ) {
        document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Please fill all inputs";
        return;
      }

      // console.log(client_name);
      // console.log(client_email);
      // console.log(subject);
      // console.log(type_of_sale);
      // console.log(property_price);
      // console.log(selectAll);
      // console.log(messageType);
      // console.log(preview_price);

      let savedData = {
        client_name: client_name,
        client_email: client_email,
        subject: subject,
        text: text,
        type_of_sale: type_of_sale,
        property_price: property_price,
        message_type: messageType,
        discount_fee: discountFee,
        preview_price: this.preview_fee,
        legel_fees: this.legel_fee,
        land_registry_fee: this.land_registry_fee,	
        land_registry_search:  this.land_registry_search,
        search_packs:  this.search_packs,
        mortgage_fee:   this.mortgage_fee,
        vat_fee:   this.vat_fee,
        id_verfication_fee:  this.id_verfication_fee,
        electronic_transfer_fee:   this.electronic_transfer_fee,
        archive_fee:   this.archive_fee,
        lawyer_fee:   this.lawyer_fee,
        sdlt_fee: this.SDLTfee,
        stamp_duty_fee:  this.stamp_duty_fee,
        storage_fee: this.storage_fee, 
        mortgage_discharge_fee: this.mortgage_discharge_fee, 
        draft_contract_fee: this.draft_contract_fee, 
        ownership_fee: this.ownership_fee,
        bankruptcy_fee: this.bankruptcy_fee,
        transfer_fee: this.transfer_fee 
      };
      console.log('dsfsdf',savedData);
      // return true;
      let data = {
        to: client_email,
        subject: subject,
        name: client_name,
        type_of_sale: type_of_sale,
        property_price: property_price,
        messageType: messageType,
        preview_price: this.preview_fee,
        discount_fee: discountFee,
        text: text,
        legel_fees: this.legel_fee,
        land_registry_fee: this.land_registry_fee,	
        land_registry_search: this.land_registry_search,
        search_packs: this.search_packs,
        mortgage_fee: this.mortgage_fee,
        vat_fee:  this.vat_fee,
        id_verfication_fee:  this.id_verfication_fee,
        electronic_transfer_fee: this.electronic_transfer_fee,
        archive_fee:  this.archive_fee,
        lawyer_fee: this.lawyer_fee,
        sdlt_fee: this.SDLTfee,
        stamp_duty_fee: this.stamp_duty_fee,
        storage_fee: this.storage_fee, 
        mortgage_discharge_fee: this.mortgage_discharge_fee, 
        draft_contract_fee: this.draft_contract_fee, 
        ownership_fee: this.ownership_fee,
        bankruptcy_fee: this.bankruptcy_fee,
        transfer_fee: this.transfer_fee 
      };
      // console.log("data", data)
      console.log("data hereeee", savedData);
      let a = await this.store.dispatch("cases/createEmailsLogs", savedData);
      console.log("a", a);
      await axios.post(`${process.env.VUE_APP_API_URL}/emails/send`, data);
      // console.log(axios);
      console.log(data);

      document.getElementById("client_name").value = "";
      document.getElementById("client_email").value = "";
      document.getElementById("subject").value = "";
      document.getElementById("type_of_sale").value = "";
      document.getElementById("property_price").value = "";
      document.getElementById("select-all").checked = "";
      document.getElementById("message-type").value = "";
      document.getElementById("text").value = "";
      document.getElementById("legel_fee_range").value = "";
      this.type_of_sale = '';
      
      this.legel_fee = '';
      this.land_registry_fee = '';
      this.land_registry_search = '';
      this.search_packs = '';
      this.vat_fee = '';
      this.mortgage_fee = '';
      this.id_verfication_fee = '';
      this.electronic_transfer_fee = '';
      this.archive_fee = '';
      this.lawyer_fee = '';
      this.stamp_duty_fee = '';

      this.preview_fee = 0;
      
      setTimeout(function() {
          open.value = false;
          }, 800);

      document.getElementsByClassName("help-desk-success")[0].innerHTML =
        "Email quotation sent successfully!";
    }
  }
};
</script>